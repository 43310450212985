@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,600;1,900&display=swap');
@import url('https://rsms.me/inter/inter.css');
html {
  font-family: 'Inter', sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}

* {
  box-sizing: border-box;
}

html,
body,
#root,
#main {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  user-select: none;
}

body {
  background: #f0f0f0;
  font-family: 'Inter', sans-serif;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 1s ease 0.3s forwards;
}

header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 40px;
  align-items: center;
  position: fixed;
}

header p {
  font-weight: bold;
  font-size: 0.8rem;
  transition: all 1.2s ease-out;
}

header p.hidden {
  opacity: 0;
}

section {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.section--container {
  margin-top: 5vh;
	margin-right: 25%;
}

.section--container.hidden {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.section--container h1 {
  font-weight: 900;
  font-size: 8rem;
  letter-spacing: -6px;
  font-style: italic;
  width: 30%;
  line-height: 0;
  font-family: 'Nunito Sans', sans-serif;
}

.support--content {
  position: relative;
  /* top: -25%; */
  /* left: 300px; */
}

.support--content p {
  width: 350px;
  margin-bottom: 48px;
  line-height: 1.5rem;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -100;
}

button {
  background-color: #dd5c18;
  border: none;
  border-radius: 4px;
  color: white;
  font-weight: bolder;
  pointer-events: all;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  box-shadow: inset 0 0 0 0.09px black;

  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
}

button:hover {
  box-shadow: inset 250px 0 0 0 black;
  transform: scale(1.1);
}

/* CUSTOMIZER */

.customizer {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-bottom: 25px;
}

.customizer span {
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;
}

.customizer span svg {
  width: 24px;
  padding-right: 5px;
}

.share {
  bottom: 40px;
  position: absolute;
  right: 40px;
}

.zoom {
  position: absolute;
  right: 20px;
  top: 50%;
  color: #00000056;
  font-size: 0.6rem;
  border: 1px solid #00000000;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  transition: border, color 1s ease;
}

.zoom:hover {
  color: black;
}

/* PICKER */

.picker {
  position: absolute !important;
  bottom: 4em;
  left: 50%;
  transform: translateX(-50%);
  width: 120px !important;
  height: 120px !important;
}

.color-options {
  display: flex;
  gap: 10px;
  margin: 10px;
  align-items: center;
  justify-content: center;
}

.cities-options {
	position: absolute;
	top: 20px;
}
.customizer-header {
	padding:20px;
}
.customizer-side {
	position:absolute;
	left:0;
	bottom:0;
	width:33%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 80px);
  margin: 40px; 
  transition: all 0.25s ease;
}
.customizer-side .frame {
	background:white;
	border-radius:8px;
	min-width:60%;
	padding:14px;
	width: 100%;
	overflow-y: auto;
	scrollbar-width: thin; /* Can be "auto", "thin", or "none" */
    scrollbar-color: #888 #f1f1f1; /* Thumb and track color */
	max-width: 600px;
	z-index: 9;
	
}
.customize_option {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
	padding: 2px;
}
.customize_option img {
	width:40px;
}
.customize_option .fbutton_container.center {
  padding:0px;
}
.radio-inputs {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.radio-inputs.colors .radio-tile{
	min-height: 36px;
  height: 36px;
  width: 36px;
  margin: 0px 2px;
}
.radio-inputs.styles .radio-tile{
  min-height:60px;
  height:60px;
  width:60px;
  margin: 2px 6px;
}

.radio-inputs.styles .radio-tile.dark,
.radio-inputs.styles .radio-tile.night{
 border: 2px solid rgba(0, 0, 0, 0.5);
}
.radio-inputs.styles .radio-tile.original:hover{
 border-color: #2260ff;
}
.radio-inputs.styles .radio-tile.LED,
.radio-inputs.styles .radio-tile.original{
 border: 2px solid rgba(212, 127, 50, 0.5);
}
.radio-inputs.styles .radio-tile.original:hover{
 border-color: #2260ff;
}

.radio-inputs.styles .radio-tile.active{
 border-color: #2260ff !important;
}
.radio-inputs.styles .radio-tile.active .radio-label {
 color:black;
}

.radio-inputs.styles .radio-tile.LED{
  --glow-color: rgb(255, 255, 255);
  --glow-spread-color: rgb(34, 96, 255);
  --enhanced-glow-color: rgb(34, 96, 255);
  --btn-color: rgb(204, 204, 204);
  color: var(--glow-color);
  font-size: 15px;
  font-weight: bold;
  background-color: var(--btn-color);
  outline: none;
  box-shadow: 0 0 5px 1px var(--glow-color), 0 0 4px 1px var(--glow-spread-color), inset 0 0 .75em .25em var(--glow-color);
  text-shadow: 0 0 .5em var(--glow-color);
  position: relative;
  transition: all 0.3s;
}
.radio-inputs.styles .radio-tile.LED:hover {
 color: var(--btn-color);
 background-color: var(--glow-color);
 box-shadow: 0 0 5px 1px var(--glow-color), 0 0 4px 1px var(--glow-spread-color), inset 0 0 .75em .25em var(--glow-color);
 border-color: #2260ff;
}
.radio-inputs.styles .radio-tile.LED.active {
 color: var(--btn-color);
 background-color: var(--glow-color);
 box-shadow: 0 0 5px 1px var(--glow-color), 0 0 4px 1px var(--glow-spread-color), inset 0 0 .75em .25em var(--glow-color);
 border-color: #2260ff;
}

.zoom-tile .radio-label {
	line-height: 12px;
}
.radio-tile.zoom-tile{
  min-height:40px;
  height:50px;
  
}
.radio-tile.city-tile{
  min-height:40px;
  height:50px;
  
}

.zoom_cm {
font-size:8px;
}
.radio-inputs > * {
  margin: 6px;
}

.radio-input:checked + .radio-tile {
  border-color: #2260ff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #2260ff;
}

.radio-input:checked + .radio-tile:before {
  transform: scale(1);
  opacity: 1;
  background-color: #2260ff;
  border-color: #2260ff;
}

.radio-input:checked + .radio-tile .radio-icon svg {
  fill: #2260ff;
}

.radio-input:checked + .radio-tile .radio-label {
  color: #2260ff;
}

.radio-input:focus + .radio-tile {
  border-color: #2260ff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #b5c9fc;
}

.radio-input:focus + .radio-tile:before {
  transform: scale(1);
  opacity: 1;
}

.radio-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  min-height: 80px;
  border-radius: 0.5rem;
  border: 2px solid #b5bfd9;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
}

.radio-tile:hover {
  border-color: #2260ff;
}

.radio-tile.active {
  border-color: #2260ff;
}


.radio-icon svg {
  width: 2rem;
  height: 2rem;
  fill: #494949;
}

.radio-label {
  color: #707070;
  transition: 0.375s ease;
  text-align: center;
  font-size: 13px;
}

.customizer-side .frame::-webkit-scrollbar {background-color:#fff;height:4px}
.customizer-side .frame::-webkit-scrollbar-track {background-color:#fff}
.customizer-side .frame::-webkit-scrollbar-thumb {background-color:#babac0;border-radius:4px}

.customizer-side .custom .item {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding:4px;
	margin:4px;
	border: 1px solid black;
	border-radius:8px;
	
}
.customizer-side .custom .item span {
	text-transform:capitalize;	
}
.customizer-side .frame_size {
	width:64px;	
}

.frame .splide__pagination {
  bottom: 70px;
  left: auto;
  padding: 0 1em;
  position: absolute;
  right: 0;
  z-index: 1;
  display:none;
}
.frame .splide__arrow--prev {
  left: -1em;
}
.frame .splide__arrow--next {
  right: -1em;
}
.size_selector{
	width:100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding-bottom: 10px;
	margin-top:4px;
}
.size_selector .size_item {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding:0px;
	margin:0px;
	border-radius:4px;
	cursor:pointer;
	opacity:0.5;
	transition: all 0.1s ease;
	border-style: solid;
	border-width: 1px;
	border-color: #86868b;
	background-color: #fffc;
	/* width:80px; */
	/* height:120px; */
}
.fbutton_container.price {
	float: left;
  padding: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.fbutton_container.price_top {
	float: right;
  padding: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: -15px;
}
.fbutton_container.price span {
	display:block
}
.frame_template {
	display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  height:100%;
  width:100%;
  
}
.frame_template .frame_size{
	 text-shadow: -1px 1px 0 #fff,
                1px 1px 0 #fff,
                1px -1px 0 #fff,
                -1px -1px 0 #fff;
	text-align:center;
	z-index: 12;
	font-size:18px;
}

				
.frame_template .water,
.frame_template .roads {
position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
	filter: invert(1);
	mix-blend-mode: multiply;
	opacity:0.2;
	border-radius:4px;
}		
.frame_template .frame_border {
position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
	border-radius:4px;
}

.frame_template.active .water,
.frame_template.active .roads {
	opacity:0.4;
}
.back_btn {
	min-width: 100px;
}
.next_btn {
	min-width: 100px;
}
.back_btn .legend,
.next_btn .legend{
position: absolute;
  font-size: 8px;
  margin-top: 58px;
  margin-right: -6px;
  margin-left: -14px;
  color: black;
}
  
.city_selector {
	display: flex;
	flex-direction: row;
  justify-content: left;
	align-items: center;
}
.city_selector_item {
	display:inline;
	padding:15px;
	height:60px;
	display: flex;
	justify-content: center;
	box-sizing: border-box;
	margin: 0px 4px;
	cursor:pointer;
	border-radius: 12px;
	border-style: solid;
	border-width: 1px;
	border-color: #86868b;
	background-color: #fffc;
	transition: all 0.1s ease;
	box-shadow: 5px 5px 15px 0 #ababab4d;
}

.city_selector_item:hover {
	border: 1px solid #0071e3;
	background: #f1f4f8;
}
.city_selector_item:hover img {
	opacity:1;
}
.city_selector_item.active {
	border: 1px solid #0071e3;
	background: #f1f4f8;
}
.city_selector_item.active img{
	opacity:1;
	
}

.fbutton_container {
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	padding-top:8px;
}
.fbutton_container.center {
	justify-content: center;
}
.fbutton_container.orientation {
	max-height:90px;
}

.fbutton {
	transition: all 0.1s ease;
	border-radius:12px;
	border-style: solid;
	border-width: 1px;
	border-color: #86868b;
	background: white;
	padding:15px;
	line-height:1;
	height:60px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin:4px;
	cursor:pointer;
	text-align:center;
	font-family: 'Nunito Sans', sans-serif;
	font-weight:800;
	font-size:8px;
  text-transform: uppercase;
}

.fbutton.scale {
	font-size:10px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.fbutton .zoom_numeric {
	font-size:14px;
}

.fbutton.portrait {
	aspect-ratio: 15/20;
  display: block;
  height: 100%;
  max-height:80px;
  width: auto;
  min-width: 60px;
  padding: 0;
	border: 2px solid white;
	margin: 0px 4px;
}
.fbutton.landscape {
	aspect-ratio: 20/15;
  display: block;
  height: auto;
  width: auto;
  min-height: 70px;
  max-height:80px;
  padding: 0;
	border: 2px solid white;
	margin: 0px 4px;
}

.fbutton .icon {
	height:100%;
	display: block;
	width: 100%;
}
.fbutton:hover {
	border: 1px solid #0071e3;
	background: #f1f4f8;
}
.fbutton.active {
	border: 1px solid #0071e3;
	background: #f1f4f8;
}

.frame_city_text {
  text-align: left;
  font-family: 'Nunito Sans', sans-serif;
  text-transform: uppercase;
  margin: 10px 4px 2px 4px;
  font-size: 12px;
}
.frame_city_text.center {
  text-align: center;
}

.frame_header_text {
	text-align:center;
	text-transform:uppercase;
	font-family: 'Nunito Sans', sans-serif;
}

.city_selector_item img{
	opacity:0.4;
	max-width:120px;
	max-height:36px;
	transition: all 0.25s ease;
}
button.active {
	border: 1px solid black;
}
.size_item_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding:2px;
	margin:0px;
}

.size_selector .size_item.active{
	border: 1px solid #0071e3;
	background: #f1f4f8 !important;
	opacity:1;
}
.badge.static {
	position:relative
}
.badge {
    position: absolute;
    bottom: 2px;
    right: 2px;
    background-color: rgb(0, 0, 0);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 10px;
	font-family: 'Nunito Sans', sans-serif;
	z-index:10;
}
.splide__track {
  overflow-y: visible;
}
.customizer-side .custom .item .circle{
  border: 2px solid black;
}
.fbutton_container .circle.squared {
	margin: 0px 0px;
  width: 30px;
  height: 30px;
  height:60px;
	border-radius:12px;
	border-style: solid;
	border-width: 1px;
	border-color: #86868b;
	background-color: #fffc;
  transition: transform 0.6s cubic-bezier(0.85, 0, 0.15, 1);
  text-align: center;
  padding-top: 22px;
  text-transform: uppercase;
  color:white;
  font-size:12px;
	transition: all 500ms ease;
  text-shadow: -1px 1px 0 #000,
                1px 1px 0 #000,
                1px -1px 0 #000,
                -1px -1px 0 #000;
				flex: 1;
}
.circle.squared.active {
  transform: scale(1);
  border: 2px solid #c4a854;
  flex-grow: 2;
}
.circle.squared:hover {
  transform: scale(1);
  flex-grow: 3;
  cursor: pointer;
}
.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid grey;
  transition: transform 0.6s cubic-bezier(0.85, 0, 0.15, 1);
  text-align: center;
  padding-top: 22px;
  text-transform: uppercase;
  color:white;
  font-size:12px;
  text-shadow: -1px 1px 0 #000,
                1px 1px 0 #000,
                1px -1px 0 #000,
                -1px -1px 0 #000;
}

.circle.big {
  width: 64px;
  height: 64px;
}
.circle.active {
  transform: scale(1.2);
  border: 2px solid #c4a854;
  
}
.circle:hover {
  transform: scale(1.2);
  cursor: pointer;
}
.fbutton_container .circle {
	margin: 0px 6px;
  font-size:14px;
  font-weight:600;
}

.decals {
  position: absolute;
  left: 50px;
  bottom: 40px;
}

.decals p {
  font-family: 'Inter var';
  font-size: 0.7rem;
  padding-bottom: 15px;
}

.decals img {
  width: 24px;
  filter: saturate(0) invert(1) brightness(5.5);
  transition: all 0.2s ease-in-out;
}

.decals img:hover {
  filter: none;
  transform: scale(1.2);
  cursor: pointer;
}
.decal.active img {
  filter: none;
  transform: scale(1.2);
  cursor: pointer;
}

.decals--position {
  position: absolute;
  left: 20px;
  top: 50%;
  color: #00000056;
  font-size: 0.6rem;
  border: 1px solid #00000000;
  padding: 10px 10px;
  border-radius: 50px;
  font-weight: 600;
  cursor: pointer;
  transition: border 1s ease;
}

.decals--position:hover {
  color: black;
  border: 1px solid #00000056;
}

.upload {
  margin-top: -5px;
  cursor: pointer;
}

.upload:hover {
  color: white;
}

.decals--container {
  display: flex;
  gap: 20px;
}

.exit {
  position: absolute;
  top: 40px;
  right: 40px;
  background-color: black;
  box-shadow: inset 0 0 0 0.09px black;
}

.exit:hover {
  box-shadow: inset -250px 0 0 0 black;
}
.checkbox-wrapper-46 input[type="checkbox"] {
  display: none;
  visibility: hidden;
}

.checkbox-wrapper-46 .cbx {
  margin: auto;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.checkbox-wrapper-46 .cbx span {
  display: inline-block;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-46 .cbx span:first-child {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid #9098a9;
  transition: all 0.2s ease;
}
.checkbox-wrapper-46 .cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-46 .cbx span:first-child:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #506eec;
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
}
.checkbox-wrapper-46 .cbx span:last-child {
  padding-left: 8px;
}
.checkbox-wrapper-46 .cbx:hover span:first-child {
  border-color: #506eec;
}

.checkbox-wrapper-46 .inp-cbx:checked + .cbx span:first-child {
  background: #506eec;
  border-color: #506eec;
  animation: wave-46 0.4s ease;
}
.checkbox-wrapper-46 .inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}
.checkbox-wrapper-46 .inp-cbx:checked + .cbx span:first-child:before {
  transform: scale(3.5);
  opacity: 0;
  transition: all 0.6s ease;
}

@keyframes wave-46 {
  50% {
    transform: scale(0.9);
  }
}
.sidebar_bottom{
	position: sticky;
	bottom: 0;
	height: 64px;
	padding-top: 6px;
	background:white;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin: 0px -4px 0px -4px;
}
.order_container{
	text-align:center;
}
.links_btn{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}
.links_btn button{
	min-width: auto;
}
.radio-inputs.styles .splide {
	width:100%;
	margin: 0px -8px 0 4px;
}
.radio-inputs.colors .radio-tile span.radio-label {
	font-size: 10px;
}
.radio-inputs.colors .radio-tile.tosca_green span.radio-label,
.radio-inputs.colors .radio-tile.blue span.radio-label,
.radio-inputs.colors .radio-tile.tivoli_blue span.radio-label,
.radio-inputs.colors .radio-tile.black span.radio-label{
	color:white;
}
.radio-inputs.colors .radio-tile.gold span.radio-label{
	color:white;
}
.radio-inputs.colors .radio-tile.mirror span.radio-label{
	color:white;
}

.radio-inputs.colors .radio-tile.mirror span.radio-label:before, .radio-inputs.colors .radio-tile.mirror span.radio-label:after {
  content: "";
  position: absolute;
  top: 18px;
  left: 0;
  right: 0;
  height: 2px;
  background: #000;
  border-radius: 4px;
}
.radio-inputs.colors .radio-tile.mirror span.radio-label:before {
  transform: rotate(45deg);
}
.radio-inputs.colors .radio-tile.mirror span.radio-label:after {
  transform: rotate(-45deg);
}
.cross-stand-alone span {
  display: block;
}
.photo-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -100px;
  transform: translateX(-100%);
  transition: 0.5s;
  opacity:0.8;
}

.photo-container .glass {
  position: relative;
  width: 90px;
  height: 100px;
  background: linear-gradient(#fff2, transparent);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 25px 25px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  border-radius: 10px;
  margin: 0 -25px;
  backdrop-filter: blur(10px);
  transform: rotate(calc(var(--r) * 1deg));
  overflow:hidden;
  background-size:cover;
  background-position:center;
  cursor:pointer;
}

.photo-container:hover {
  transform: translateX(0);
  margin: 0 0px;
  
  margin-top: -100px;
  opacity:1;
}
.photo-container:hover .glass {
  transform: rotate(0deg);
  margin: 2px;
}

.photo-container .glass svg {
  font-size: 2.5em;
  fill: #fff;
}
.photo-container .glass .photo {
max-width: 100%;
}
.card {
  width: 300px;
  height: 200px;
  background: #243137;
  position: relative;
  display: grid;
  place-content: center;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

#logo-main, #logo-second {
  height: 100%;
}

#logo-main {
  fill: #bd9f67;
}

#logo-second {
  padding-bottom: 10px;
  fill: none;
  stroke: #bd9f67;
  stroke-width: 1px;
}

.border {
  position: absolute;
  inset: 0px;
  border: 2px solid #bd9f67;
  opacity: 0;
  transform: rotate(10deg);
  transition: all 0.5s ease-in-out;
}

.bottom-text {
  position: absolute;
  left: 50%;
  bottom: 13px;
  transform: translateX(-50%);
  font-size: 6px;
  text-transform: uppercase;
  padding: 0px 5px 0px 8px;
  color: #bd9f67;
  background: #243137;
  opacity: 0;
  letter-spacing: 7px;
  transition: all 0.5s ease-in-out;
}

.content {
  transition: all 0.5s ease-in-out;
}

.content .logo {
  height: 50px;
  position: relative;
  width: 80px;
  overflow: hidden;
  transition: all 1s ease-in-out;
}

.content .logo .logo2 svg,
.content .logo .logo1 svg {
  /* width: 100%; */
  height: 100%;
}

.content .logo .logo2 img,
.content .logo .logo1 img {
  /* width: 100%; */
  height: 100%;
}

.content .logo .logo1 {
  height: 50px;
  position: absolute;
  left: 0;
}

.content .logo .logo2 {
  height: 33px;
  width: 100%;
  position: absolute;
  left: 80px;
}

.content .logo .trail {
  position: absolute;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.content .logo-bottom-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: 30px;
  color: #bd9f67;
  padding-left: 8px;
  font-size: 11px;
  opacity: 0;
  letter-spacing: none;
  transition: all 0.5s ease-in-out 0.5s;
}

.card:hover {
  border-radius: 0;
  transform: scale(1.1);
}

.card:hover .logo {
  width: 220px;
  animation: opacity 1s ease-in-out;
}

.card:hover .border {
  inset: 15px;
  opacity: 1;
  transform: rotate(0);
}

.card:hover .bottom-text {
  opacity: 1;
  transform: translateX(-50%);
}

.card:hover .content .logo-bottom-text {
  opacity: 1;
  letter-spacing: 2px;
}

.card:hover .trail {
  animation: trail 1s ease-in-out;
}

.loading {
	position:absolute;
	z-index:1001;
	width:100%;
	height:50%;
	left:0;
	top:0;
	display: flex;
	justify-content: center;
	align-items: center;
	color:white;
	opacity:0;
	transition:0.5s;
}
.loading.active {
	opacity:1;
}

.loading.progress .card {
  border-radius: 0;
  transform: scale(1.1);
}

.loading.progress .card .trail {
  animation: trail 1s ease-in-out;
}

.loading.progress .card .logo {
  width: 220px;
  animation: opacity 1s ease-in-out;
}

.loading.progress .card .border {
  inset: 15px;
  opacity: 1;
  transform: rotate(0);
}

.loading.progress .card .bottom-text {
  opacity: 1;
  transform: translateX(-50%);
}
.loading.progress .card{
  animation: trail 1s ease-in-out;
}
.loading.progress .card .content .logo-bottom-text {
  opacity: 1;
  letter-spacing: 2px;
}

.cls-1 {
fill: #fff;
}
@keyframes opacity {
  0% {
    border-right: 1px solid transparent;
  }

  10% {
    border-right: 1px solid #bd9f67;
  }

  80% {
    border-right: 1px solid #bd9f67;
  }

  100% {
    border-right: 1px solid transparent;
  }
}

@keyframes trail {
  0% {
    background: linear-gradient(90deg, rgba(189, 159, 103, 0) 90%, rgb(189, 159, 103) 100%);
    opacity: 0;
  }

  30% {
    background: linear-gradient(90deg, rgba(189, 159, 103, 0) 70%, rgb(189, 159, 103) 100%);
    opacity: 1;
  }

  70% {
    background: linear-gradient(90deg, rgba(189, 159, 103, 0) 70%, rgb(189, 159, 103) 100%);
    opacity: 1;
  }

  95% {
    background: linear-gradient(90deg, rgba(189, 159, 103, 0) 90%, rgb(189, 159, 103) 100%);
    opacity: 0;
  }
}
@media screen and (max-width: 1279px) {
	.customizer-side {
		width:100%;
		margin: 0px;
		top: auto;
		height: 35%;
	}
	
	.customizer-side .frame {
		max-width: 100%;
		padding: 4px 14px;
	}
	.section--container {
		margin: 0;
	  width: 100%;
	  height: 100%;
	  text-align: center;
	}
	.section--container h1 {
	  width: 100%;
	}
	.support--content { 
		position: absolute;
		  bottom: 0;
		  width: 100%;
	}
	.support--content div{ 
		  width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
	.support--content button{ 
		margin-bottom: 30px;
	}
	.support--content p {
	  width: 100%;
	  margin-bottom: 0px;
	  padding:8px
	}
	.exit {
	  top: 20px;
	  right: auto;
	  left: 20px;
	}
	.share {
	  bottom: auto;
	  top: 20px;
	  right: 20px;
	}
	.frame_city_text {
	  margin: 4px 4px 2px 4px;
	}
	.size_item_container {
	  transform: scale(0.8);
	}
}
